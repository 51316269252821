// feature
import styled from 'styled-components'
import { breakpointUp } from '@/utils/breakpoints'
import { Seo, LayoutContainer } from '@/utils/globalComponents'

// components
import Layout from '@/components/Layout'
import { AttentionContainer } from '@/components/AttentionContainer'
import { HeadingLevel1 } from '@/components/HeadingLevel1'
import { HeadingLevel2 } from '@/components/HeadingLevel2'
import { RoundedCornersContainer } from '@/components/RoundedCornersContainer'
import { TextThemeColor } from '@/components/Utils'

const IndexPage = props => {
  return (
    <Layout>
      <Seo page="privacy" />
      <Privacy>
        <LayoutContainer>
          <PrivacyHeadingContainer>
            <HeadingLevel1 note="privacy policy">プライバシーポリシー</HeadingLevel1>
          </PrivacyHeadingContainer>
          <PrivacyTextContainer>
            個人情報保護法などの法遵守は当社の基本的企業理念であり、お客様の信頼と支持がさらなる企業発展の最大要因と捉え、個人情報を適正に管理し、お客様のご要望に沿って利用することが使命と認識しております。
            <br />
            当社では、お客様からお預かりする個人情報を保護するため、個人情報保護方針を以下に示すとおりに定め、当方針に基づき、個人情報の保護に努めてまいります。
          </PrivacyTextContainer>
          <PrivacySectionContainer>
            <HeadingLevel2>個人情報の取扱いについて</HeadingLevel2>
            <PrivacyDescriptionList>
              <PrivacyDescriptionListInner>
                <dt>個人情報とは</dt>
                <dd>
                  個人に関する情報であって、当該情報に含まれる社名、担当者名、住所、メールアドレス、その他の記述、または個人別に付けられた番号、記号その他の符号、画像もしくは音声によって当該個人を識別できるもの（当該情報だけでは識別できないが、他の情報と容易に照合することができ、それによって当該個人を識別できるものを含む）をいいます。
                </dd>
              </PrivacyDescriptionListInner>
              <PrivacyDescriptionListInner>
                <dt>利用目的</dt>
                <dd>
                  当社ではお客様からご提供いただいた個人情報については、お客様のご要望に当社が的確に対応し、営業上のサービスを提供するためにのみ利用します。
                </dd>
              </PrivacyDescriptionListInner>
            </PrivacyDescriptionList>
          </PrivacySectionContainer>
          <PrivacySectionContainer>
            <HeadingLevel2>第三者への提供</HeadingLevel2>
            <PrivacyTextContainer>
              当社ではお客様からご提供いただいた個人情報を第三者に提供することはありません。ただし、個人情報保護の体制を整備した協力企業との間で厳密な個人情報保護の契約を結び、収集した利用者の情報処理を委託することがあります。
            </PrivacyTextContainer>
          </PrivacySectionContainer>
          <PrivacySectionContainer>
            <HeadingLevel2>個人情報の開示等</HeadingLevel2>
            <PrivacyTextContainer>
              当社ではお客様からご提供いただいた個人情報については開示を請求することができます。また、開示の結果、個人情報の訂正・削除を請求することもできます。
              <br />
              また、人事考課に関する個人情報については、開示に応じますが、業務の適正な実施に著しく支障を及ぼす場合には訂正、抹消には、応じられませんので御了承下さい。そのときは皆様に遅滞なくその旨を通知するとともに理由を御説明します。個人情報の利用目的の通知、利用目的の停止、消去又は第三者への提供の停止を求められた場合には、これに応じます。それができない場合については、皆様に遅滞なくその旨を通知し、理由を御説明します。貴殿ご自身の個人情報の開示・訂正・削除を請求される場合は、下記連絡先にご連絡をお願い致します。なお、本手続きにあたり、貴殿がご本人であることを確認させて頂くことがあります。
            </PrivacyTextContainer>
          </PrivacySectionContainer>
          <PrivacySectionContainer>
            <HeadingLevel2>個人情報保護に関するお問い合わせ</HeadingLevel2>
            <PrivacyTextContainer>
              当社における個人情報保護に関してご質問などがある場合は、下記にご連絡下さい。
            </PrivacyTextContainer>
            <PrivacyInformationListContainer>
              <RoundedCornersContainer>
                <AttentionContainer>
                  <PrivacyInformationList>
                    <li>〒604-8492</li>
                    <li>京都府京都市中京区西ノ京右馬寮町17-3</li>
                    <li>株式会社Ai-R（アイアール）</li>
                    <li>
                      <a href="tel:0758116194">TEL：075-811-6194（代）</a> FAX：075-801-3232
                    </li>
                    <li>
                      <TextThemeColor>
                        <a href="m&#97;i&#108;t&#111;:&#97;&#105;-&#114;&#64;&#97;i&#45;&#114;.k&#121;o&#116;&#111;.j&#112;">
                          &#97;&#105;-&#114;&#64;&#97;i&#45;&#114;.k&#121;o&#116;&#111;.j&#112;
                        </a>
                      </TextThemeColor>
                    </li>
                  </PrivacyInformationList>
                </AttentionContainer>
              </RoundedCornersContainer>
            </PrivacyInformationListContainer>
          </PrivacySectionContainer>
        </LayoutContainer>
      </Privacy>
    </Layout>
  )
}

const Privacy = styled.div`
  position: relative;
`

const PrivacyHeadingContainer = styled.div`
  margin-bottom: 8rem;
`

const PrivacyDescriptionList = styled.ul`
  margin-top: 1.6rem;
`

const PrivacyDescriptionListInner = styled.div`
  > dt {
    margin-bottom: 1.6rem;
    font-size: 1.8rem;
    font-weight: bold;

    ${breakpointUp('md')} {
      font-size: 2.4rem;
    }
  }

  & + & {
    margin-top: 1.6rem;
  }
`

const PrivacyInformationListContainer = styled.div`
  margin-top: 1.6rem;
`

const PrivacyInformationList = styled.ul`
  > li {
  }
`

const PrivacyTextContainer = styled.p`
  margin-top: 1.6rem;
`

const PrivacySectionContainer = styled.section`
  margin-top: 4rem;
`

export default IndexPage
